<template>
  <div class="addNewCatContainer">
    <DuInput
        type="text"
        name="title"
        :label="getFormsLables().title"
        :is-required="true"
        is-required-error-message="قم بكتابة العنوان"
        :get-ref="(e) => createFormRef(e)"
    />
    <DuInputUploader
        :label="getFormsLables().image"
        place-holder="انقر هنا لإختيار صورة"
        isRequiredMessage="لم تقم بإختيار صورة"
        :filesChanged="(e) => { uploadChange(e) }"
        :get-ref="(e) => state.photosRef = e"
        :init-files="getInitFilesIfThere()"
        :key="JSON.stringify(getInitFilesIfThere())"
        :maxFilesCount="1"
    />

    <DuButton class="saveButton" text="حفظ" @click="save()"/>
  </div>
</template>

<script>
import {onMounted, reactive} from "vue";
import AjaxWorker from "../../helpers/AjaxWorker";
import FixedNotification from "../../components/plugins/fixedNotifications/FixedNotification";
import DuInput from "../../components/DuInput";
import DuInputUploader from "../../components/DuInputUploader";
import DuButton from "../../components/DuButton";
import router from "../../router";
import {useRoute} from "vue-router";

export default {
  name: "AddNewCat",
  components: {DuButton, DuInputUploader, DuInput},
  setup(){
    const state = reactive({
      formRefs: [],
      photosRef: [],
      image: '',
      isAddingOrEditing: false,
      data: {},
      isFetchingData: false,
      photos: [],
    });
    const route = useRoute();
    const createFormRef = (ref) => state.formRefs[ref.state.name] = ref;

    const getData = () => {
      state.isFetchingData = true;
      new AjaxWorker().request("/transactions.php?action=getCats", {
        id: route.params.id,
      }).then((res) => {
        state.isFetchingData = false;
        if(res === "NOINTERNETCONNECTION" || !res.state) {
          FixedNotification.create({
            decription: "حدث خطاء أثناء الإتصال",
            icon: "errorF",
            color: 'red',
            hideAfter: 3000
          });
        } else {
          state.data = res.data[0];
          state.formRefs["title"].state.innerValue = state.data.title;
          state.photos = state.data.image.split(",");
        }
      });
    }

    onMounted(() => {
      if(route.params.id !== undefined) {
        getData();
      }
    });
    const save = () => {
      let isFormValid = true;
      Object.keys(state.formRefs).forEach((key) => {
        const el = state.formRefs[key];
        el.validateNow();
        if(!el.state.isValid) {
          isFormValid = false;
        }
      });
      state.photosRef.validateNow();
      if(state.image === '') isFormValid = state.photosRef.state.isValid;

      const params = {};
      params.title = state.formRefs["title"].state.innerValue;
      params.image =  state.photos.join(",");


      if(isFormValid) {
        state.isAddingOrEditing = true;
        let _action = "createNewCat";
        let isNewAdd = true;
        if(Object.keys(state.data).length > 0) {
          _action = "editCat";
          params.id = state.data.id;
          isNewAdd = false;
        } else {
          params.type =  'cat';
          if(route.query.type !== undefined) {
            params.type = route.query.type;
          }
        }

        new AjaxWorker().request('/transactions.php?action=' + _action, params).then((res) => {
          state.isAddingOrEditing = false;
          if (res === "NOINTERNETCONNECTION" || !res.state) {
            FixedNotification.create({
              title: 'حدث خطاء أثناء الإتصال',
              decription: 'تأكد من إتصالك بالإنترنت',
              icon: 'wifiF',
              color: 'red',
              hideAfter: 3000
            });
          } else {
            FixedNotification.create({
              title: '',
              decription: isNewAdd ? 'تمت الإضافة' : 'تم حفظ التعديلات',
              icon: isNewAdd ? 'doneF' : 'editF',
              color: 'green',
              hideAfter: 3000
            });
            router.back();
          }
        });
      }

    }
    const uploadChange = (files) => {
      state.photos = [];
      files.value.forEach((file) => {
        if(file.state === "uploaded") {
          state.photos.push(file.serial);
        }
      });
    }
    const getInitFilesIfThere = () => {
      const _files = [];
      state.photos.forEach((fileSerial) => {
        _files.push(fileSerial);
      });
      return _files;
    }

    const getFormsLables = () => {
      const lables = {
        title: 'العنوان',
        image: 'الصورة الرمزية',
      };

      if(route.query.type !== undefined) {
        if(route.query.type === "area") {
          lables.image = "صورة المدينة الرمزية";
          lables.title = "عنوان المنطقة";
        } else if(route.query.type === "cat") {
          lables.image = "صورة القسم";
          lables.title = "عنوان القسم";
        }
      }

      return lables;
    }
    return {
      state,
      getInitFilesIfThere,
      getFormsLables,
      createFormRef,
      uploadChange,
      save,
    }
  },
}
</script>

<style scoped>
.addNewCatContainer{
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
  row-gap: 20px;
  text-align: start;
}
.saveButton{
  place-self: end;
  margin: 20px 0;
}
</style>